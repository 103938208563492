enum RouteEnum {
  Main = '',
  Statue = '/statue',
  Privacy = '/privacy',
  Shop = '/shop',
  ShopPaymentReturn = '/payment-return',
  Home = '/home',
  Login = '/login',
  Logout = '/logout',
  Dashboard = '/dashboard',
  Stories = '/stories',
  CreateStory = '/create-story',
  Story = '/story',
}

export default RouteEnum
